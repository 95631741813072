<template>
  <div class="page">
    <div class="container">
      <div class="breadcrumbs">
        <!-- <a href="javascript:void(0)" class="breadcrumbs__item">Главная</a>
        <a href="javascript:void(0)" class="breadcrumbs__item">Новости</a>
        <span class="breadcrumbs__item">Название новости</span> -->
        <slot name="breadcrumbs"></slot>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
};
</script>
