<template>
  <div class="about">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <h1 class="page-title">О проекте</h1>

      <div class="page-top__row">
        <div class="page-top__col">
          <div class="page-top__img"></div>
          <template v-if="false">
            <p class="page-top__quote">
              Тут цитата основателя о том, какую миссию несет проект, про благо
              развития туризма на Байкале или все, что захочется сюда написать.
              Распространился в 1970-х годах из-за трафаретов компании Letraset,
              a затем — из-за того, что служил примером текста в программе
              PageMaker.
            </p>
            <p class="page-top__quote text-right">
              Иван Иванов, Founder GO AND GUIDE 360
            </p>
          </template>
        </div>
        <div class="page-top__col">
          <p class="page-top__text">
            GO AND GUIDE 360 – это уникальная иммерсивная платформа, объединяющая в
            себе удобные сервисы для навигации, путешествий, покупок,
            впечатлений и общения.
          </p>
          <p class="page-top__text">
            В мобильном приложении представлены снятые в формате кругового
            обзора (360°) природные достопримечательности регионов, памятники
            истории и культуры, а также уникальные туристические маршруты.
          </p>
          <p class="page-top__text">
            Не выходя из приложения вы можете подобрать и забронировать отель -
            и сразу же получаете возможность “прогуляться” по его интерьерам,
            оценить удобство номеров, познакомиться с убранством и меню местного
            ресторана.
          </p>
        </div>
      </div>

      <AboutSlider v-if="false" />

      <section class="partners-section">
        <h2 class="partners-section__title">Сервисы и возможности:</h2>
        <div class="page-top__row page-top__row--list">
          <div class="page-top__col page-top__col--list">
            <ul class="app-list app-list--about">
              <li class="app-list__item">
                Удобная интерактивная карта с фильтрами по категориям объектов
              </li>
              <li class="app-list__item">Выбор маршрута путешествия</li>
              <li class="app-list__item">
                Выбор, бронирование и оплата номеров в гостиницах
              </li>
              <li class="app-list__item">
                Бронирование и заказ столиков в ресторанах
              </li>
              <li class="app-list__item">Заказ доставки еды</li>
              <li class="app-list__item">Подбор гидов и экскурсоводов</li>
              <li class="app-list__item">Заказ и покупка сувениров</li>
              <li class="app-list__item">
                И многое другое - следите за новостями!
              </li>
            </ul>
          </div>
        </div>

        <div class="page-top__row">
          <div class="page-top__col"></div>
          <div class="page-top__col">
            <p class="about-list__text">
              Наша платформа позволяет вам, не выходя из дома, “прогуляться” по
              самым красивым местам, ознакомиться с особенностями региона и
              полностью спланировать свою поездку.
            </p>
          </div>
        </div>
      </section>
    </Page>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";
import AboutSlider from "@/components/partials/AboutSlider.vue";

export default {
  name: "About",
  components: { Page, AboutSlider },
};
</script>
