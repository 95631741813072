<template>
  <div class="about-slider">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <div class="about-slide">
          <div class="about-slide__img about-slide__img--1"></div>
          <div class="about-slide__title">Мальвина</div>
          <div class="about-slide__subtitle">Заботится о клиентах</div>
          <div class="about-slide__text">
            Тут описание Мальвины и ее задач. Каждый из нас понимает очевидную
            вещь: курс на социально-ориентированный национальный проект
            предопределяет высокую востребованность кластеризации усилий.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="about-slide">
          <div class="about-slide__img about-slide__img--2"></div>
          <div class="about-slide__title">Мальвина</div>
          <div class="about-slide__subtitle">Заботится о клиентах</div>
          <div class="about-slide__text">
            Тут описание Мальвины и ее задач. Каждый из нас понимает очевидную
            вещь: курс на социально-ориентированный национальный проект
            предопределяет высокую востребованность кластеризации усилий.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="about-slide">
          <div class="about-slide__img about-slide__img--3"></div>
          <div class="about-slide__title">Мальвина</div>
          <div class="about-slide__subtitle">Заботится о клиентах</div>
          <div class="about-slide__text">
            Тут описание Мальвины и ее задач. Каждый из нас понимает очевидную
            вещь: курс на социально-ориентированный национальный проект
            предопределяет высокую востребованность кластеризации усилий.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="about-slide">
          <div class="about-slide__img about-slide__img--4"></div>
          <div class="about-slide__title">Мальвина</div>
          <div class="about-slide__subtitle">Заботится о клиентах</div>
          <div class="about-slide__text">
            Тут описание Мальвины и ее задач. Каждый из нас понимает очевидную
            вещь: курс на социально-ориентированный национальный проект
            предопределяет высокую востребованность кластеризации усилий.
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "AboutSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          500: {
            slidesPerView: 1.5,
            centeredSlides: false,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2.5,
            centeredSlides: false,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 4,
            centeredSlides: false,
            spaceBetween: 40,
          },
        },
      },
    };
  },
};
</script>
